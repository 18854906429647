<template>
  <div>

    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>
            <validation-provider
              #default="validationContext"
              name="Password"
              rules="required|password"
            >
        <b-row>

          <!-- Full Name -->
          <b-col
            cols="12"
            md="6"
          >
              <b-form-group>
                <label for="password">New Password</label>
                <b-input-group>
                  <b-form-input
                    id="password"
                    :type="passwordFieldType"
                    v-model="new_pass"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="invalid-feedback" :style="feedbackStyle($store.getters['app/currentBreakPoint'])">{{ validationContext.errors[0] }}</div>
          </b-col>

        </b-row>
          </validation-provider>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
    v-if="new_pass"
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="submitChanges"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ name: 'licences-view', params: { id: userData.id } })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BInputGroup, BInputGroupAppend,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data(){
    return {
      
      required,
      alphaNum,
      password,

      new_pass:'',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
    async submitChanges(){
      console.log(this.userData)
      let data = {
        id: this.userData.id,
        new_pass: this.new_pass
      }
      await this.$http.post('regenerateLicencePassword', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
          this.new_pass = '';
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
